import { ComponentThemeConfig } from 'frr-web/lib/theme/theme.components'
import './table.css'

export const componentTheme: Partial<ComponentThemeConfig> = {
  modal: {
    outerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      left: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
    innerWrapper: {
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
      width: '100%',
      backgroundColor: 'white',
      padding: 24,
      borderRadius: 0,
      flex: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      outline: 'none',
    },
  },
  staticChecklist: {
    wrapper: {
      backgroundColor: 'rgba(0,0,0,0.03)',
      padding: 32,
      borderRadius: 8,
      marginBottom: 16,
      '@media-mobile': {
        padding: '32px 16px',
      },
    },
    title: {
      fontSize: 'var(--font-size-h2)',
      marginBottom: 32,
    },
    description: {
      fontSize: 'var(--font-size-h4)',
    },
    listsWrapper: {
      display: 'flex',
      alignItems: 'start',
      '@media-mobile': {
        flexWrap: 'wrap',
      },
    },
    list: {
      marginTop: 32,
      marginRight: 64,
      '@media-mobile': { marginRight: 32 },
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 0',
    },
    itemLabel: {
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-p)',
    },
    itemIcon: {
      marginRight: 4,
      width: 18,
    },
    iconAllowed: {
      color: 'green',
    },
    iconDisallowed: {
      color: 'var(--color-red)',
    },
    itemsList: {
      paddingTop: 16,
    },
  },
  dropdown: {
    wrapper: {
      border: '1px solid var(--color-form-field-border)',
      borderRadius: 'var(--form-field-border-radius)',
      height: 36,
      boxShadow: '1px 1px 1px 1pxrgba(0,0,0, 0.02)',
    },
  },
  popoverWithItems: {
    wrapper: {},
    item: {
      minWidth: 168,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      lineHeight: '42px',
      padding: '12px 16px',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      ':hover': {
        backgroundColor: 'var(--color-background-hover)',
      },
    },
    itemDisabled: {
      opacity: 0.5,
      cursor: 'default',
    },
    popover: {
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      lineHeight: 1,
    },
  },
  popoverDropdown: {
    icon: {
      marginLeft: 8,
      marginBottom: -2,
    },

    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 72,
      padding: '0 16px',

      borderRadius: 24,
      marginLeft: 24,
      backgroundColor: 'white',
      height: 36,
      cursor: 'pointer',
      '@media-mobile': {
        minWidth: 0,
        marginLeft: 16,
      },
    },
    label: {
      color: 'var(--color-black)',
      fontSize: 15.84,
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  colorPicker: {
    circle: {
      border: '2px solid var(--color-secondary)',
      height: 36,
      width: 36,
      marginRight: 4,
      ':hover': {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'var(--color-hover)',
        // filter: 'brightness(70%) saturate(300%)',
      },
    },
    labelColor: {
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-small)',
      lineHeight: 1.6,
      whiteSpace: 'nowrap',
    },
    labelModal: {
      fontWeight: 700,
      textAlign: 'center',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      height: 'var(--form-field-height)',
      width: '100%',
      maxWidth: 'calc(100% - var(--form-label-width))',
      position: 'relative',
      marginTop: 16,
      marginBottom: 16,
    },
  },
  datePicker: {
    wrapper: {
      position: 'relative',
      width: '100%',
      maxWidth: 'var(--form-field-width)',
      display: 'flex',
      alignItems: 'center',
    },
    inputWrapper: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: 0,
    },
    iconWrapper: {
      alignItems: 'center',
      borderColor: 'var(--color-form-field-border)',
      borderTopRightRadius: 'var(--form-field-border-radius)',
      borderBottomRightRadius: 'var(--form-field-border-radius)',
      borderStyle: 'solid',
      borderWidth: 1,
      cursor: 'pointer',
      display: 'flex',
      flexBasis: 80,
      height: 'var(--form-field-height)',
      justifyContent: 'center',
      position: 'relative',
    },
    hook1: {
      display: 'none',
    },
    hook2: {
      display: 'none',
    },
    errorHook: {},
    errorWrapper: {},
    reactDatePicker: `
    & .react-datepicker {
      font-family: var(--font-family);
      top: calc(var(--form-field-height) + 4px);
      border-radius: 0;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
    }
    
    & .react-datepicker__header {
      background-color: var(--color-background-app);
      padding-top: 16px;
    
      & .react-datepicker__current-month {
        font-size: var(--font-size-p);
      }
    }
    
    & .react-datepicker__header__dropdown {
      margin: 16px 0;
      & select {
        border: 1px solid rgb(204, 204, 204);
        border-radius: 0;
        padding: 6px 8px 4px;
        font-size: var(--font-size-p);
      }
    }
    
    & .react-datepicker__navigation {
      border-width: 1rem;
    }
    
    & .react-datepicker__day-name {
      font-size: var(--font-size-p);
      font-weight: 500;
      width: 34px;
      line-height: var(--font-size-p);
      padding: 9px 0 7px;
      margin: 4px;
    }
    & .react-datepicker__day {
      font-size: var(--font-size-p);
      width: 34px;
      line-height: var(--font-size-p);
      padding: 9px 0 7px;
      margin: 4px;
      border: 1px solid transparent;
      border-radius: 0;
      :hover {
        border-radius: 0;
        background-color: rgba(249, 193, 0, 0.6);
      }
    }
    & .react-datepicker__day--outside-month {
      color: var(--color-fade);
    }
    & .react-datepicker__day--selected {
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
      background-color: var(--color-background-primary);
      font-weight: 700;
    
      :hover {
        background-color: rgba(249, 193, 0, 0.6);
      }
    }
    `,
  },
  slider: {
    label: {},
    wrapper: {
      margin: '16px 0',
      width: '100%',
      paddingLeft: 8,
    },
    prefix: {
      marginLeft: 4,
      marginRight: 4,
      fontSize: 24,
      fontWeight: 700,
    },
    value: {
      fontSize: 32,
      fontWeight: 700,
    },
    valueWrapper: {
      position: 'absolute',
      right: 0,
      top: -8,
      paddingTop: 'var(--form-field-mobile-padding)',
      display: 'flex',
      alignItems: 'center',
    },
  },
  label: {
    labelTextWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    labelTextError: {
      color: 'var(--color-error)',
    },
    labelText: {
      color: 'var(--color-secondary)',
      fontWeight: 300,
      fontSize: 'var(--font-size-p)',

      '@media-mobile': {
        marginTop: 8,
        marginBottom: 8,
      },
    },
    errorIcon: {
      position: 'absolute',
      left: -28,
      top: 10,
      color: 'var(--color-error)',
      '@media-mobile': {
        left: -24,
      },
    },
    errorLabel: {
      color: 'var(--color-error)',
      fontSize: 'var(--font-size-small)',
      marginBottom: 8,
      '@media-mobile': {
        marginTop: 8,
      },
    },
    sublabelText: {
      color: 'var(--color-fade)',
      fontSize: 'var(--font-size-small)',
      marginTop: 4,
      marginBottom: 4,
      '@media-mobile': {
        marginTop: 0,
        marginBottom: 8,
      },
    },
    descriptionPopup: {
      backgroundColor: 'var(--color-background-primary)',
      border: '1px solid var(--color-border)',
      borderRadius: 3,
      boxShadow: '2px 2px 8px var(--color-background-hover, rgba(0,0,0,0.3))',
      cursor: 'pointer',
      left: -8,
      minWidth: 400,
      padding: '16px 32px',
      position: 'absolute',
      top: 36,
      zIndex: '10',
    },
    descriptionText: {
      fontSize: 'var(--font-size-h4)',
    },
    descriptionIconWrapper: {
      opacity: 0.7,
      cursor: 'pointer',
      minWidth: 18,
    },
    descriptionIcon: {
      width: 18,
      height: 18,
      marginBottom: -2,
      marginLeft: 8,
    },
    wrapper: {
      paddingTop: 'var(--form-field-mobile-padding)',
      paddingBottom: 'var(--form-field-mobile-padding)',
      fontSize: 'var(--font-size-h3)',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: 'var(--form-label-width)',
      paddingRight: 8,
      '@media-mobile': { width: '100%', marginRight: 0, paddingBottom: 8 },
    },
  },
  codeInput: {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: '16px 0',
      '@media-mobile': {
        margin: '0 0 32px',
      },
    },
    input: {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: 'var(--form-field-border-radius)',
      caretColor: 'transparent',
      cursor: 'pointer',
      fontSize: 24,
      height: 56,
      marginRight: 8,
      padding: 0,
      textAlign: 'center',
      width: 36,

      ':focus': {
        outline: 0,
        background: 'transparent',
        borderColor: 'var(--color-bob-primary)',
      },
    },
  },
  toggle: {
    wrapper: {
      border: '1px solid transparent',
      background: 'var(--color-form-field-border)',
      height: 32,
      margin: '0 24px',
      transition: 'border 0.1s, background 0.2s',
      ':hover': {
        borderColor: 'var(--color-form-field-border-focus)',
      },
      '@media-mobile': {
        marginTop: 8,
        marginLeft: 'auto',
        marginRight: 0,
        ':hover': {
          borderColor: 'transparent',
        },
      },
    },
    wrapperActive: {
      borderColor: 'var(--color-active)',
      backgroundColor: 'var(--color-active)',
      flexDirection: 'row-reverse',
    },
    wrapperFocus: {
      borderColor: 'var(--color-form-field-border-focus)',
      '@media-mobile': {
        borderColor: 'transparent',
      },
    },
    circle: {
      backgroundColor: 'white',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'white',
      height: 25,
      width: 25,
    },
  },
  buttonGroup: {
    wrapper: {
      padding: '4px',
      boxShadow: '1px 1px 2px 2px rgb(0 0 0 / 5%)',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      height: 32,
    },
    item: {
      borderRadius: 4,
      padding: '2px 6px',
      margin: '0 2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      cursor: 'pointer',
    },
    itemActive: {
      backgroundColor: 'rgb(79, 133, 240, 0.1)',
    },
    icon: {
      fontSize: 16,
    },
    iconActive: {
      color: 'var(--color-active)',
    },
    label: {
      fontSize: 12,
      padding: '0 4px',
    },
    labelActive: {
      color: 'var(--color-active)',
    },
  },
  radioGroup: {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      maxWidth: 'var(--form-field-width)',
    },
    radioOuterError: {
      borderColor: 'var(--color-error)',
    },
    radioOuter: {
      width: 18,
      height: 18,
      flexShrink: 0,
      padding: 3,
      borderColor: 'var(--color-primary)',
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
    radioOuterActive: {},
    radioInner: {
      backgroundColor: 'var(--color-primary)',
    },
    radioInnerActive: {},
    item: {
      flexDirection: 'row-reverse',
      width: 112,
      padding: '0 24px var(--form-field-mobile-padding) 20px',
      justifyContent: 'flex-end',
      fontSize: 'var(--font-size-p)',
    },
    label: {
      paddingLeft: 12,
      lineHeight: '18px',
    },
    errorWrapper: {},
  },
  singleCheckbox: {
    wrapper: {
      width: 'calc(100% - var(--form-label-width))',
    },
    input: {
      height: 16,
      width: 16,
      margin: 0,
      cursor: 'pointer',
    },
    inputDisabled: { cursor: 'default' },
  },

  select: {
    errorWrapper: {
      borderColor: 'var(--color-error)',
    },
    wrapper: {
      position: 'relative',
      width: 'calc(100% - var(--form-label-width))',
      maxWidth: 'var(--form-field-width)',
      zIndex: 0,
      backgroundColor: 'var(--color-background-content)',
    },
    icon: {
      fontSize: 'var(--font-size-h2)',
      opacity: 0.6,
    },
    iconMobile: {
      position: 'absolute',
      right: 20,
      top: 'calc((var(--form-field-height)  - 24px) / 2)',
      zIndex: -1,
    },
    menuPortal: {
      zIndex: 9999,
    },
    menu: {
      position: 'absolute',
      width: '100%',
      backgroundColor: 'var(--color-background-primary)',
      border: '1px solid var(--color-form-field-border)',
      boxShadow: 'var(--shadow-overlay)',
      marginLeft: -2,
      marginTop: -3,
      marginBottom: 4,
      borderRadius: 'var(--form-field-border-radius)',
      overflow: 'hidden',
      zIndex: 9999,
    },
    option: {
      cursor: 'pointer',
      display: 'block',
      fontSize: 'inherit',
      padding: '16px 16px',
      width: '100%',
      userSelect: 'none',
      paddingLeft: 28,
      transition: 'background-color 20ms color 20ms',
      ':disabled': {
        backgroundColor: 'transparent',
        color: 'var(--color-fade)',
        cursor: 'default',
      },
    },
    optionHover: {
      backgroundColor: 'var(--color-row-background-active)',
    },
    optionActive: {
      backgroundColor: 'transparent',
      color: 'var(--color-primary)',
    },
    placeholder: {
      color: 'var(--color-fade)',
      fontWeight: 400,
    },
    select: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderColor: 'var(--color-form-field-border)',
      borderRadius: 'var(--form-field-border-radius)',
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow: undefined,
      boxSizing: 'border-box',
      color: 'var(--color-input)',
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: 'var(--font-size-p)',
      minHeight: 'var(--form-field-height)',
      justifyContent: 'space-between',
      outline: '0 !important',
      padding: '0 var(--form-field-padding-horizontal)',
      position: 'relative',
      textOverflow: 'ellipsis',
      transition: 'all 100ms',
      width: 'var(--form-field-width)',
      zIndex: 10,
      '@media-mobile': {
        paddingRight: 48,
      },
      ':hover': {
        borderColor: 'var(--color-form-field-border-focus)',
      },
    },
    valueContainer: { padding: 0, fontSize: 'var(--font-size-p)' },
    value: {
      fontSize: 'var(--font-size-p)',
      fontWeight: 700,
      marginRight: 42,
    },
  },

  textInput: {
    errorWrapper: {
      borderColor: 'var(--color-error)',
    },
    errorHook: {
      backgroundColor: 'var(--color-error)',
    },
    readOnlyWrapper: {
      borderWidth: 0,
    },
    readOnlyInput: {
      textAlign: 'left',
    },
    readOnlyHook: {
      display: 'none',
    },
    prefix: {
      paddingLeft: 'var(--form-field-padding-horizontal)',
      cursor: 'pointer',
    },
    postfix: {
      paddingRight: 'var(--form-field-padding-horizontal)',
      paddingLeft: 16,
      color: 'var(--color-secondary)',
      fontSize: 'var(--font-size-p)',
    },
    wrapper: {
      flexGrow: 1,
      boxShadow: '1px 1px 1px 1px rgba(0,0,0, 0.02)',
      display: 'flex',
      alignItems: 'center',
      height: 'var(--form-field-height)',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 'var(--form-field-border-radius)',
      borderColor: 'var(--color-form-field-border)',
      maxWidth: 'var(--form-field-width)',
      width: 'calc(100% - var(--form-label-width))',
      position: 'relative',
    },
    input: {
      width: '100%',
      height: '100%',
      border: 0,
      backgroundColor: 'transparent',
      padding: '0 var(--form-field-padding-horizontal)',
      fontSize: 'var(--font-size-p)',
      fontWeight: 600,
    },
    hook: {
      display: 'none',
    },
    disabledInput: {
      color: 'var(--color-fade)',
    },
    disabledWrapper: {},
    errorInput: {},
  },
  textArea: {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 'var(--form-field-border-radius)',
      borderColor: 'var(--color-form-field-border)',
      width: '100%',
      height: 144,
      maxWidth: 'calc(100% - var(--form-label-width))',
      position: 'relative',
      margin: '8px 0',
    },
    input: {
      width: '100%',
      height: '100%',
      border: 0,
      cursor: 'pointer',
      padding: '8px var(--form-field-padding-horizontal)',
      fontFamily: 'var(--font-family)',
      fontSize: 'var(--font-size-p)',
      fontWeight: 600,
      lineHeight: 1.4,
      // Required in case the field is extended by the user
      backgroundColor: 'var(--color-background-content)',
      zIndex: 2,
    },
    disabled: {},
  },
  table: {
    noResultsLabel: {
      opacity: 0.6,
    },
    noResultsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '300px',
    },
    loadingWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '300px',
    },
    loading: {},
    tableWrapper: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    listWrapper: {
      flex: 1,
    },
    rowCell: {
      padding: '0 8px 0 0',
    },
    rowWrapper: {
      alignItems: 'center',
      borderBottom: '1px solid rgba(0,0,0,0.04)',
      display: 'flex',
      height: 48,
      paddingLeft: 8,
      paddingRight: 8,
      transition: 'all ease 0.15s',

      ':hover': {
        backgroundColor: '#f0fbfc',
      },
    },
    rowText: {
      fontSize: 12,
      whiteSpace: 'nowrap',
      padding: '0 8px 0 0',
    },
    headerOuterWrapper: {},
    headerWrapper: {
      alignItems: 'center',
      backgroundColor: 'var(--color-background-app)',
      borderRadius: 4,
      display: 'flex',
      height: 36,
      marginBottom: 12,
      paddingLeft: 8,
      paddingRight: 28,
    },
    headerCell: {
      alignItems: 'center',
      color: 'rgb(134, 141, 154)',
      display: 'flex',
      fontWeight: 500,
      overflow: 'hidden',
      padding: '0 8px 0 2px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    headerText: {
      fontSize: 12,
    },
    descriptionOuterWrapper: {
      color: 'inherit',
    },
    descriptionIcon: {
      width: 14,
      fontSize: 14,
      color: 'inherit',
      marginLeft: '5px',
    },
    descriptionIconWrapper: {
      color: 'inherit',
      height: 16,
    },
    sortingIconsContainer: {
      display: 'flex',
      flexDirection: 'column',
      color: 'inherit',
      width: 25,
    },
    sortingIconWrapper: {
      alignItems: 'center',
      color: 'inherit',
      cursor: 'pointer',
      display: 'flex',
      height: 12,
      overflow: 'hidden',
      ':hover': {
        color: 'var(--color-hover)',
      },
    },
    sortingIcon: {
      flex: 'none',
      color: 'inherit',
      fontSize: 25,
      width: 25,
      height: 25,
    },
    descriptionPopup: {
      padding: 20,
    },
    descriptionText: {
      fontSize: 12,
    },
  },
  optionGroup: {
    errorWrapper: {
      borderColor: 'var(--color-error)',
    },
    wrapper: {
      backgroundColor: 'white',
      // width: 'calc(100% - var(--form-label-width))',
      maxWidth: 'calc(100% - var(--form-label-width))',
      cursor: 'pointer',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'var(--color-border)',
      padding: '0 4px',
      height: 'calc(var(--form-field-height) + 4px)',
      borderRadius: '4px',
    },
    itemActive: {
      backgroundColor: 'var(--color-active)',
      color: 'var(--color-white)',
      borderBottomColor: 'var(--color-active)',
      borderTopColor: 'var(--color-active)',
      borderLeftColor: 'var(--color-active)',
      borderRightColor: 'var(--color-active)',
      boxSizing: 'content-box',
      zIndex: 1,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(var(--form-field-height) - 8px)',
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: '4px',
      margin: '0 1px 1px',
      padding: '0 16px',
      borderBottomColor: 'var(--color-background-primary)',
      borderTopColor: 'var(--color-background-primary)',
      borderLeftColor: 'var(--color-background-primary)',
      borderRightColor: 'var(--color-background-primary)',
      transition: 'border 0.3s ease-out',
      ':hover': {
        borderBottomColor: 'var(--color-active)',
        borderTopColor: 'var(--color-active)',
        borderLeftColor: 'var(--color-active)',
        borderRightColor: 'var(--color-active)',
      },
    },
    label: {
      textAlign: 'center',
      fontSize: 'var(--font-size-p)',
    },
    labelActive: {},
  },

  button: {
    common: {
      alignItems: 'center',
      border: 0,
      boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.01)',
      cursor: 'pointer',
      display: 'flex',
      fontFamily: 'var(--font-family)',
      height: 36,
      justifyContent: 'center',
      outline: 'none',
      transition: 'all 0.7s ease',
      '@media-mobile': {
        width: '100%',
        marginTop: 16,
      },
      ':focus': {
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.35), 0 -2px 8px rgba(0, 0, 0, 0.05)',
      },
    },

    chromeless: {
      background: 'var(--color-background-app)',
      padding: '0 16px',
      height: 24,
      minWidth: 96,
      borderRadius: 4,
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)',

      ':hover': {
        background: 'rgba(0,0,0,0.2)',
      },
      ':disabled': {
        opacity: 0.6,
        pointerEvents: 'none',
      },
      ':focus': {
        filter: 'brightness(80%)',
      },
    },

    label: {
      lineHeight: 1.0,
      marginBottom: '0.1rem',
      textTransform: 'none',
    },

    primary: {
      backgroundColor: 'var(--color-bob-primary)',
      borderColor: 'transparent',
      borderRadius: 4,
      color: 'var(--color-white)',
      fontWeight: 700,
      minWidth: 96,
      padding: '0 16px',
      '@animation': 'button-primary-animation 200ms ease-out',
      ':hover': {
        filter: 'brightness(80%)',
      },
      ':disabled': {
        opacity: 0.6,
        pointerEvents: 'none',
      },
      ':focus': {
        filter: 'brightness(80%)',
      },
    },

    primaryLabel: {
      fontSize: 14,
      fontWeight: 500,
    },

    secondary: {
      backgroundColor: 'var(--color-white)',
      border: '1px solid rgb(0,0,0,0.1)',
      borderRadius: 4,
      color: 'var(--color-primary)',
      padding: '0 16px',
      fontSize: 'var(--font-size-14)',
      '@animation': 'button-secondary-animation 200ms ease-out',
      ':hover': {
        filter: 'brightness(80%)',
      },
      ':disabled': {
        opacity: 0.6,
        pointerEvents: 'none',
      },
    },
    secondaryLabel: {
      fontSize: 14,
      fontWeight: 500,
    },

    spinner: {},
  },

  icon: {
    edit: {
      svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="106 287 41 41" version="1.1" width="100%" height="100%"><g><path fill="currentColor" d="m139.16 289.02c-1.2148-0.82153-2.4093-1.383-3.6056-1.8664l-1.2844 1.7675 7.3028 5.3067 1.2844-1.7675c-1.2539-1.5418-2.4824-2.6188-3.6972-3.4403zm-5.7164 1.0383-1.2844 1.7675 7.3028 5.3067 1.2844-1.7675zm-2.2202 3.0554-17.334 23.854 3.9697 1.2896 16.735-22.702zm4.0836 3.0131-16.643 22.656 2.2523 3.5376 17.61-23.9zm-21.862 21.863-1.8834 9.5262 8.3239-4.591-2.3632-3.5249z"/></g></svg>',
    },
    info: {
      svg: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-7 -7 46 46"><circle cx="16" cy="16" r="20" stroke-width="2" stroke="currentColor" fill="none" /><path fill="currentColor" stroke="none" d="M17.333 25.333v-16h-4.667v2.667h2v13.333h-2v2.667h6.667v-2.667z"></path><path fill="currentColor" stroke="none" d="M14.667 4h2.667v2.667h-2.667v-2.667z"></path></svg>',
    },
    settings: {
      svg: '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100" fill="currentColor" width="100%" height="100%"><path d="m50.011 0c-2.8839 0-5.7139 0.25936-8.4642 0.7281l-1.3424 14.221c-2.8444 0.79287-5.5365 1.9015-8.0546 3.322l-10.99-9.0785c-4.6419 3.286-8.7054 7.3259-11.991 11.968l9.1012 10.99c-1.4188 2.5151-2.552 5.214-3.3447 8.0546l-14.197 1.35c-0.46893 2.75-0.728 5.58-0.728 8.49 0 2.8897 0.25747 5.7085 0.7281 8.4641l14.198 1.3425c0.79274 2.8406 1.926 5.5395 3.3447 8.0546l-9.0785 10.99c3.2796 4.6294 7.3161 8.6885 11.945 11.968l11.013-9.1013c2.5197 1.4217 5.2082 2.5515 8.0546 3.3447l1.3424 14.221c2.7503 0.4688 5.5803 0.7054 8.4642 0.7054 2.8838 0 5.6911-0.2366 8.4414-0.7054l1.3424-14.221c2.8465-0.7932 5.5349-1.923 8.0546-3.3447l11.013 9.1013c4.6293-3.2797 8.6658-7.3388 11.945-11.968l-9.0785-10.99c1.4188-2.5151 2.552-5.214 3.3447-8.0546l14.198-1.3425c0.47063-2.7556 0.7281-5.5744 0.7281-8.4641 0-2.8848-0.25907-5.7131-0.7281-8.4642l-14.198-1.3424c-0.79274-2.8406-1.926-5.5395-3.3447-8.0546l9.1012-10.99c-3.2855-4.6423-7.349-8.6821-11.991-11.968l-10.99 9.0785c-2.5181-1.4205-5.2102-2.5291-8.0546-3.322l-1.3424-14.221c-2.7503-0.46874-5.5576-0.7281-8.4414-0.7281zm0 30.967c10.516 0 19.022 8.528 19.022 19.044s-8.5053 19.044-19.022 19.044c-10.516 0-19.044-8.528-19.044-19.044s8.528-19.044 19.044-19.044z"/></svg>',
    },
  },

  // ==============================================
  // Navigation for whitelabel preview (only)
  // ==============================================

  navigation: {
    carrot: {
      display: 'none',
    },
    progressBar: {
      height: 6,
      background: 'rgba(0,0,0,0.1)',
      marginRight: 12,
      marginLeft: -12,
      '@media-mobile': {
        margin: 0,
      },
    },
    progressBarActive: {
      background: 'var(--color-active)',
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
    },
    progressBarCompleted: {
      background: 'var(--color-active)',
    },
    progressBarError: {
      background: 'var(--color-error)',
    },
    wrapperOuter: {
      backgroundColor: 'var(--color-background-primary)',
      boxShadow: '0 0 8px 8px rgb(0 0 0 / 4%)',
      height: 84,
      overflow: 'hidden',
      padding: '0 var(--form-padding-horizontal)',
      zIndex: 2,
      '@media-mobile': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    wrapperInner: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      width: '100%',
      margin: '0 auto',
      '@media-mobile': {
        height: 'auto',
        margin: '8px -2px',
      },
    },
    itemTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 2,
    },
    itemTitle: {
      display: 'none',
      '@media-mobile': {
        color: 'var(--color-secondary)',
        display: 'block',
        fontSize: '1.6rem',
        fontWeight: 600,
        opacity: 1,
      },
    },
    itemLabel: {
      fontSize: 16,
      height: 19,
      opacity: 0.5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '@media-mobile': {
        display: 'none',
      },
    },
    itemNumber: {
      fontSize: 16,
      opacity: 0.5,
      paddingRight: 8,
      '@media-mobile': {
        display: 'none',
      },
    },
    itemLabelCompleted: {
      opacity: 1,
    },
    itemLabelActive: {
      opacity: 1,
      fontWeight: 'bold',
    },
    itemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      justifyContent: 'center',
      marginLeft: 0,
    },
    item: {
      display: 'flex',
      width: '100%',
      margin: '0 12px 12px 0',
      '@media-mobile': {
        justifyContent: 'center',
        marginBottom: 0,
      },
    },
    itemActive: {},
    itemCircle: {},
    itemCircleActive: {},
  },

  // ======================
  // File Upload
  // ======================

  uploadDropzone: {
    container: {
      cursor: 'pointer',
      width: '400px',
      height: '80px',
      justifyContent: 'center',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border 0.24s ease-in-out',
      ':hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    dropzoneSublabel: {
      fontSize: 12,
    },
    acceptedFilesLabel: {
      fontSize: '12px',
      color: 'green',
    },
    rejectedFilesLabel: {
      fontSize: '12px',
      color: 'var(--color-error)',
    },
    acceptedFileItem: {
      fontSize: '12px',
    },
    rejectedFileItem: {
      fontSize: '12px',
    },
    errorMessage: {
      fontSize: '12px',
      color: 'var(--color-error)',
    },
    section: {
      paddingTop: '20px',
    },
    sectionSingleItem: {
      paddingTop: '0px',
      display: 'flex',
      width: '100%',
      whiteSpace: 'nowrap',
      alignItems: 'center',
    },
    imageItem: {
      marginRight: '10px',
      width: '40px',
      height: '40px',
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '4px 20px 0',
    },
    listSingleItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '0 20px',
    },
    removeItemIcon: {
      color: 'var(--color-error)',
      cursor: 'pointer',
      marginLeft: '10px',
      paddingTop: '4px',
    },
  },
}
